<template>
  <div class="areroom">
    <div class="room-warp">
      <div class="room-title">
        <!-- <div class="title-left">
					免费皮肤> <span>{{ roomData.room_name }}</span>
				</div> -->
        <div class="title-right">
          <!-- <i class="el-icon-s-home"></i> -->
          <!-- <span>房间编号: {{ roomData.room_num }} </span> -->
          <span class="break">
            <i class="el-icon-timer"></i>
            <span>截止时间: {{ roomData.run_lottery_time }}</span>
          </span>
        </div>
      </div>
      <div class="room-top">
        <div class="roomtop-left">
          <div class="room-img">
            <img :src="roomData.img" />
            <span class="span1" v-if="roomData.type == 2">主播</span>
            <span class="span1" v-if="roomData.type == 1">官方</span>
          </div>
          <div class="room-int">
            <h5>{{ roomData.room_name }}</h5>
            <div class="roomint-top">
              <span>规则说明:{{ roomData.desc }}</span>
            </div>
            <div class="roomint-bot"></div>
          </div>
        </div>
        <div class="num">
          <div class="num-box">
            <span class="num-span">{{
              roomData.count ? roomData.count : 0
            }}</span>
            <span>饰品数量</span>
          </div>
          <div class="num-box">
            <span class="num-span">{{
              roomData.person_num ? roomData.person_num : 0
            }}</span>
            <span>参与人数</span>
          </div>
          <div class="num-box">
            <span class="num-span">{{
              roomData.pool ? roomData.pool : 0
            }}</span>
            <span>饰品价值</span>
          </div>
        </div>
      </div>
      <div class="roomtop-right">
        <div
          class="btn-warp"
          @click="addRoom"
          v-if="!status && roomData.status != 2"
        >
          <img
            v-if="roomData.condition_type == 1 || roomData.condition_type == 3"
            src="../assets/img/suo1.png"
          />
          <span>加入房间</span>
        </div>
        <div class="btn-warp" v-if="status && roomData.status != 2">
          <!-- <img v-if="roomData.condition_type == 1 || roomData.condition_type == 3"
						src="../assets/img/suo1.png" /> -->
          <span>已加入</span>
        </div>
        <div class="btn-warp" v-if="roomData.status == 2">已结束</div>
      </div>
      <div class="box-bottom">
        <div
          :class="selectType == 1 ? 'select' : ''"
          @click="changeSelectType(1)"
        >
          奖池
        </div>
        <div
          :class="selectType == 2 ? 'select' : ''"
          @click="changeSelectType(2)"
        >
          参与用户
        </div>
        <div
          :class="selectType == 3 ? 'select' : ''"
          @click="changeSelectType(3)"
        >
          中奖用户
        </div>
      </div>
      <!-- <div class="room-win" v-if="roomData.status == 2">
				<div class="win-top">
					<h5>中奖纪录</h5>
					<h6>
						已送出 <span>{{ player_skin.length }}</span> 件 价值
						<img src="../assets/img/money.png" /><span>{{
              player_skin_price
            }}</span>

					</h6>
				</div>
				<div class="win-con1">
					<ul>
						<li v-for="(item, index) in player_skin" :key="index">
							<div class="win-warp">
								<div class="win-warp1">
									<img :src="item.skin_img" />
								</div>
								<div class="win-name">
									<img :src="item.player_img" />
									<span>{{ item.player_name }}</span>
								</div>
								<span class="span1">{{ item.name}} {{ item.name }}</span>
							</div>
						</li>
					</ul>
				</div>
			</div> -->
      <div class="boxlist-bot" v-if="selectType == 1">
        <ul>
          <li v-for="(item, index) in list" :key="index" class="card">
            <div class="boxlist-warp">
              <div class="boxlist1-top">
                <img :src="item.img" />
              </div>
              <div>
                <div class="boxlist1-bot" :title="item.name">
                  {{ item.name }}
                </div>
                <div class="list-pirce">
                  <div class="pirce-left">
                    <img src="../assets/new/money.png" />
                    <div>
                      <span>{{ item.price }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li style="height: 0"></li>
          <li style="height: 0"></li>
          <li style="height: 0"></li>
          <li style="height: 0"></li>
        </ul>
      </div>
      <div class="boxlist-bot" v-if="selectType == 3">
        <ul>
          <li v-for="(item, index) in player_skin" :key="index" class="card">
            <div class="boxlist-warp">
              <div class="boxlist1-top">
                <span class="ico4">
                  <img :src="item.player_img" />
                  {{ item.player_name }}
                </span>
                <img :src="item.skin_img" />
              </div>
              <div>
                <div class="boxlist1-bot" :title="item.name">
                  {{ item.name }}
                </div>
                <div class="list-pirce">
                  <div class="pirce-left">
                    <img src="../assets/new/money.png" />
                    <div>
                      <span>{{ item.price }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li style="height: 0"></li>
          <li style="height: 0"></li>
          <li style="height: 0"></li>
          <li style="height: 0"></li>
        </ul>
      </div>
      <!-- <div class="room-win">
				<div class="win-top">
					<h5>奖池奖品</h5>
					<h6>
						剩余 <span>{{ list.length }}</span> 件 价值<span>{{ price }}</span>
						<img src="../assets/img/money.png" />
					</h6>
				</div>
				<div class="win-con">
					<ul>
						<li v-for="(item, index) in list" :key="index">
							<div class="win-warp">
								<img :src="item.img" />
								<span>{{ item.name}} {{ item.price }}</span>
							</div>
						</li>
					</ul>
				</div>
			</div>
 -->
      <div class="room-num" v-if="selectType == 2">
        <div class="num-list">
          <ul>
            <li v-for="(item, index) in player_list" :key="index">
              <img :src="item.img" />
              <span>{{ item.name }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="pass" v-if="passState">
      <div class="pass-box" >
        <span class="pass-hint" @click="hidePass"
          ><i class="el-icon-close"></i
        ></span>
        <div class="pass-title">输入房间口令</div>
        <div class="input">
          <input type="text" v-model="password" />
        </div>
        <div class="pass-btn">
          <el-button type="warning" @click="passAddRoom">确认</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      passState: false,
      id: "",
      roomData: {},
      list: [],
      player_list: [],
      price: 0,
      player_skin: [],
      player_skin_price: 0,
      status: false,
      selectType: 1,
    };
  },
  mounted() {
    //console.log(this.$route.query.id);
    this.id = this.$route.query.id;
    this.status = this.$route.query.status;
    this.getRoomList();
  },
  methods: {
    changeSelectType(e) {
      this.selectType = e;
    },
    //加入房间
    addRoom() {
      if (
        this.roomData.condition_type == 1 ||
        this.roomData.condition_type == 3
      ) {
        this.passState = true;
        return;
      } else {
        var param = {
          free_room_id: this.id,
        };
      }

      this.$axios
        .post("index/Free/addFreeRoom", this.$qs.stringify(param))
        .then((res) => {
          //console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.getRoomList();
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        });
    },
    //隐藏密码框
    hidePass() {
      this.passState = false;
    },
    //密码加入房间
    passAddRoom() {
      let param = {
        free_room_id: this.id,
        password: this.password,
      };
      this.$axios
        .post("index/Free/addFreeRoom", this.$qs.stringify(param))
        .then((res) => {
          //console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.getRoomList();
            this.passState = false;
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        });
    },
    //房间数据
    getRoomList() {
      let param = {
        free_room_id: this.id,
      };
      this.$axios
        .post("index/Free/freeRoomDetail", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.player_list = data.data.player_list;
            this.player_list.forEach((e) => {
              console.log(e);
              if (e.id == JSON.parse(localStorage.getItem("userInfo")).id) {
                this.status = true;
              }
            });
            this.roomData = data.data;
            this.list = data.data.skin_list;

            if (data.data.status == 2) {
              this.player_skin = data.data.player_skin;
              //中奖记录总价
              for (let i = 0; i < this.player_skin.length; i++) {
                this.player_skin_price += Number(this.player_skin[i].price);
              }
              this.player_skin_price = this.player_skin_price.toFixed(2);
            }

            //奖池奖品总价
            /* for (let i = 0; i < this.list.length; i++) {
							  this.price += Number(this.list[i].price);
							}*/
            this.price = data.data.pool;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.box-bottom {
  display: flex;
  background-color: #2b2c37;
  color: white;
  margin-top: 12px;
  border-radius: 16px;

  div {
    border-radius: 16px;
    width: 33%;
    text-align: center;
    padding: 6px 12px;
  }

  .select {
    background-color: #ffba00;
    color: black;
    font-weight: 600;
  }
}

@media screen and (max-width: 767px) {
  .areroom {
    padding: 0 !important;
  }

  .boxlist-bot {
    zoom: 0.64;
    padding: 10px 0px;

    ul {
      display: flex !important;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 12px;
      // display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(160px, 160px));
      // gap: 8px;

      li {
        margin-left: 0 !important;
        margin-top: 12px;
        width: 48% !important;
        // background: url("../assets/img/arenaroom.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        max-height: auto !important;
        height: auto !important;

        .boxlist-warp {
          margin: 0 !important;
          padding-bottom: 0 !important;
        }

        .boxlist1-top {
          padding: 20px 50px 60px 50px !important;
          background-size: 100% 100%;
          text-align: center;
          // border-bottom: 3px solid #f1a920;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          height: auto !important;
          // position: absolute;
          // top: 0;
          // bottom: 0;
          // left: 0;
          // right: 0;
          // margin: auto;

          // box-shadow: 0px 5px 10px #f1a920;
          img {
            width: auto;
            height: 100% !important;
            max-height: auto !important;
            margin: 12px 0 !important;
            cursor: pointer;
          }
        }

        .boxlist1-bot {
          padding: 8px 0;
          width: 100%;
          margin: auto;
          color: white;
          font-family: "微软雅黑";
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: left;
          white-space: nowrap;
          padding: 0 !important;
          line-height: 24px;
          font-size: 12px !important;
          font-weight: bold;
          word-wrap: break-word;
          position: absolute;
          left: 0;
          left: 6px !important;
          bottom: 0;
        }

        .boxlist1-bot2 {
          height: 16px;
          width: 100%;
          margin: auto;
          color: rgb(204, 204, 204);
          font-family: "微软雅黑";
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 12px !important;
          text-align: right;
          word-wrap: break-word;
          position: absolute;
          right: 0;
          bottom: 12px !important;
        }

        .boxlist1-bot1 {
          padding: 8px;
          display: flex;
          align-items: center;

          img {
            width: 25px;
            height: 25px;
            border-radius: 50%;
          }

          span {
            margin-left: 8px;
            color: #c3c3e2;
            font-size: 12px !important;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .list-pirce {
          padding: 0px 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          font-size: 12px !important;
          bottom: -22px;
        }

        .ico {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 12px;
          color: #04f540;
          background-color: rgba(0, 0, 0, 0.5);
          padding: 2px 4px;
        }

        .pirce-left {
          display: flex;
          align-items: center;
          padding-right: 10px;

          img {
            width: auto;
            height: 20px;
            margin-right: 5px;
          }

          & > div {
            flex: 1;
            color: #f4bd50;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span:last-child {
              font-size: 12px !important;
              font-family: "微软雅黑";
              color: white;
            }
          }
        }
      }
    }
  }
}

.boxlist-bot {
  padding: 10px 0px;

  ul {
    display: flex;
    width: 100%;
    margin-bottom: 12px;
    flex-wrap: wrap;
    justify-content: space-between;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(160px, 160px));
    // gap: 8px;

    li {
      width: 160px;
      width: 24%;
      background: url("../assets/img/arenaroom.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      cursor: pointer;
      margin-top: 12px;

      .boxlist-warp {
        // background-color: #24252f;
        border-radius: 5px;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 20px);

        .boxlist1-top {
          background-size: 100% 100%;
          overflow: hidden;
          padding-top: 24px;
          display: flex;
          align-items: center;
          flex-direction: column;
          padding: 50px 20px 70px 20px;

          // height: 130px;
          img {
            width: auto !important;
            height: 120px !important;
            max-height: 120px !important;
          }

          .ico4 {
            position: absolute;
            left: 5px;
            top: 5px;
            display: flex;
            align-items: center;
            color: white;
            img {
              width: 55px !important;
              height: 55px !important;
              border-radius: 50%;
            }
          }
        }

        .boxlist1-bot {
          padding: 8px 0;
          width: 100%;
          margin: auto;
          color: white;
          font-family: "微软雅黑";
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          line-height: 24px;
          font-size: 16px;
          word-wrap: break-word;
          position: absolute;
          left: 0;
          bottom: 0;
          text-align: center;
          margin: auto;
        }

        .boxlist1-bot2 {
          height: 24px;
          width: 100%;
          margin: auto;
          // color: rgb(204, 204, 204);
          color: white;
          font-family: "微软雅黑";
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-align: right;
          word-wrap: break-word;
          position: absolute;
          right: 0;
          bottom: 24px;
        }

        .boxlist1-bot1 {
          padding: 8px;
          display: flex;
          align-items: center;

          img {
            width: 25px;
            height: 25px;
            border-radius: 50%;
          }

          span {
            margin-left: 8px;
            color: #c3c3e2;
            font-size: 16px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .list-pirce {
          padding: 0px 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          text-align: center;
          left: 0;
          right: 0;
          bottom: -12px;
          margin: auto;
        }

        .ico {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 14px;
          color: #04f540;
          background-color: rgba(0, 0, 0, 0.5);
          padding: 2px 4px;
        }

        .pirce-left {
          display: flex;
          align-items: center;
          padding-right: 10px;
          margin: auto;

          img {
            width: auto;
            height: 20px;
            margin-right: 5px;
          }

          & > div {
            flex: 1;
            color: #f4bd50;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span:last-child {
              font-family: "微软雅黑";
              font-size: 16px;
              color: white;
            }
          }
        }

        .pirce-right {
          // color: #ffae00 !important;
          color: white;
          // font-weight: 700;
          font-size: 16px;
          font-family: "微软雅黑";
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.areroom {
  width: 100;
  height: 100vh;
  // overflow: hidden;
  overflow-y: auto;
  padding: 0 500px;
  background-color: #06090f;

  .room-warp {
    height: 100%;
    padding: 16px;
  }

  .room-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-left {
      font-size: 16px;
      color: #848492;

      span {
        color: #c3c3e2;
      }
    }

    .title-right {
      font-size: 16px;
      color: #848492;

      i {
        margin-left: 10px;
      }

      span {
        margin-left: 5px;
      }
    }
  }

  .room-top {
    margin-top: 30px;
    background-color: #2b2c37;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .num {
      width: 100%;
      padding: 0 16px;
      margin-top: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .num-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;

        span {
          color: #848492;
          font-size: 10px;
        }

        span:last-child {
          margin-top: 5px;
        }

        .num-span {
          color: white;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }

    .roomtop-left {
      display: flex;
      align-items: flex-start;
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid #444659;

      .room-img {
        // padding-right: 20px;
        // border-right: 1px solid #444659;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          border: 1px solid #e9b10e;
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }

        .span1 {
          margin-top: -13px;
          font-size: 13px;
          background-color: #e9b10e;
          padding: 0 6px;
          border-radius: 4px;
        }

        .span2 {
          margin-top: 10px;
          color: #c3c3e2;
        }
      }

      .room-int {
        margin-left: 20px;
        color: white;

        h5 {
          font-size: 16px;
          font-weight: 600;
        }

        span {
          font-size: 14px;
          color: #848492;
        }
      }
    }
  }

  .roomtop-right {
    margin-top: 12px;

    .btn-warp {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e9b10e;
      border-radius: 5px;
      padding: 9px 22px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }

      span {
        font-size: 15px;
        color: #1a1c24;
        font-weight: 600;
      }
    }

    .btn-warp:hover {
      cursor: pointer;
    }

    .btn-warp1 {
      font-size: 15px;
      color: #c3c3e2;
    }
  }

  .win-top {
    display: flex;
    margin-top: 15px;
    align-items: center;

    h5 {
      font-size: 20px;
      font-weight: 400;
      color: #c3c3e2;
    }

    h6 {
      margin-left: 20px;
      font-weight: 400;
      font-size: 16px;
      display: flex;
      align-items: center;
      color: #848492;

      img {
        height: 16px;
        width: auto;
      }

      span {
        padding: 0 4px;
        color: #e9b10e;
      }
    }
  }

  .room-win {
    margin-top: 30px;

    .win-con {
      margin-top: 20px;
      background-color: #2b2c37;
      padding: 16px;
      border-radius: 5px;

      ul {
        display: flex;
        flex-wrap: wrap;

        // justify-content: space-evenly;
        li {
          width: 12.5%;

          .win-warp {
            background-image: url("../assets/img/lucky.jpg");
            background-size: 100% 100%;
            margin: 2px;
            padding: 0 10px;
            background-color: #fff;
            position: relative;
            border-radius: 5px;

            span {
              position: absolute;
              top: 4px;
              left: 4px;
              background-color: rgba(0, 0, 0, 0.6);
              font-size: 12px;
              padding: 0 5px;
              border-radius: 10px;
              color: #fff;
            }

            img {
              width: 100%;
              height: auto;
              // height: 70px;
              // width: auto;
            }
          }
        }
      }
    }
  }

  .win-con1 {
    margin-top: 20px;
    background-color: #2b2c37;
    padding: 16px;
    border-radius: 5px;

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 14.28%;

        .win-warp {
          margin: 2px;
          background-color: #24252f;
          position: relative;
          border-radius: 5px;

          .win-warp1 {
            padding: 0 10px;
            background-image: url("../assets/img/lucky.jpg");
            background-size: 100% 100%;

            img {
              width: 100%;
              max-height: 70px;
            }
          }

          .win-name {
            margin-top: -25px;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
            }

            span {
              font-size: 14px;
              color: #c3c3e2;
              // margin-top: 5px;
              padding-bottom: 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .span1 {
            position: absolute;
            top: 4px;
            left: 4px;
            background-color: rgba(0, 0, 0, 0.6);
            font-size: 12px;
            padding: 0 5px;
            border-radius: 10px;
            color: #fff;
          }

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  .room-num {
    margin-top: 30px;

    .num-list {
      margin-top: 20px;
      background-color: #2b2c37;
      padding: 16px;
      border-radius: 5px;

      ul {
        margin-top: -40px;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        // padding-bottom: 22px;
        // display: grid !important;
        // grid-gap: 10px 0;
        justify-content: space-between;

        // grid-template-columns: repeat(auto-fit, minmax(21.5%,20%));
        // margin: auto;
        li {
          // margin-right: 20px;
          margin-top: 40px;
          width: 50px;
          height: 50px;
          text-align: center;
          margin-left: 4px;

          span {
            display: inline-block;
            width: 50px;
            text-overflow: ellipsis;
            text-align: center;
            overflow: hidden;
            font-size: 12px;
            white-space: nowrap;
            color: white;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .pass-box {
    // position: fixed;
    // top: 30%;
    // left: 50%;
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .pass-hint {
      position: absolute;
      right: 0;
      top: 0;

      i {
        font-size: 20px;
        color: #c3c3e2;
      }

      i:hover {
        cursor: pointer;
      }
    }

    .pass-title {
      display: flex;
      justify-content: center;
      color: #c3c3e2;
      font-size: 20px;
    }

    .input {
      margin-top: 15px;
      width: 100%;

      input {
        width: 100%;
        height: 40px;
        outline: none;
        border: none;
        color: #848492;
      }

      input:focus {
        border: none;
      }
    }

    .pass-btn {
      margin-top: 15px;
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}
</style>